import styled from "@emotion/styled";
import { Divider, Grid, Menu, Modal } from "semantic-ui-react";
import React from "react";
import theme from "@styles/theme";

export const StickyTopMenu = styled(({visible, darkTheme, ...others})=><Menu {...others} />)`
    display: ${props=>props.visible  === true ? "block !important" : "none !important"};
    padding: 20px 0 20px 0;
    background-color: ${p => (p.darkTheme ? 'black !important' : 'white')};
    color: ${p => (p.darkTheme ? 'white' : 'black')};
`

export const Header = styled.div`
    padding-right:10px;
    padding-left:10px;
    padding-top:20px;
`

export const HeaderStep = styled.div`
    color: ${theme.brand.colors.white};
    text-align: right;
    font-weight: bold;
`

export const SelectedPlateWrapper = styled.div`
 botton {
    width: 100% !important;
 }


 #select-style-continue {
    button {
        width: 100% !important;
    }
 }
`

export const HeaderDesktop = styled.div`
    background-color: black;
    color: white;
    margin-bottom:32px;
`

export const PlateStyleFilterDesktop = styled.div`
 margin-bottom: 30px;
 button {
    padding: 8px 18px 8px 18px;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 18px;
    background: transparent;
    margin-right: 7px;
    cursor: pointer;
    font-size:12px;
    font-weight:700;
    font-family: itc-avant-garde-gothic-pro,sans-serif;
 }

 .active {
    background: #393939;
 }
`

export const FilterTitle = styled.div`
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    margin-bottom:8px;
    margin-top:24px;
`

export const PlateDesigListContainer = styled.div`
    .column {
        padding: 8px !important;
    }
`
export const EmptySelectionTitle = styled.div`
    color: #707070;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
`

export const ModalButton = styled.div`
color: #FFF;
font-size: 16px;
font-weight: 700;
text-align:left;
margin-bottom:5px;
`

export const CheckIconColumn = styled.div`
    color:#3DAE2B;
    margin-left: 8px;
    padding-right: 0px !important;
    padding-top:18px !important;
    padding-left: 0px !important;
`

export const GroupNameColumn = styled(Grid.Column)`    
    padding-left: 8px !important;
    cursor: pointer;
`

export const FilterDivider = styled(Divider)`
    &&&& {
    color: #C6C6C8;
    border-width: 2px;
    margin-top:20px;
    margin-bottom:20px;
    margin-left: -8px;
    }
`

export const MobileHeader = styled.div`
    padding-right:10px;
    margin-left:-5px;
    padding-top:20px;
`
export const ColumnGroup = styled(Grid.Column)`
    margin-bottom: 36px;
`

export const CustomModal = styled(Modal)`
    background-color: black !important;
    margin-left:30px;
    width:100%;
    .actions{
        margin-top: -14px;
    }
`
export const CardColumn =styled(Grid.Column)`
    padding-left: 8px !important;
    padding-right: 8px !important;
`