import styled from "@emotion/styled";
import React from "react";
import { Menu, Segment } from "semantic-ui-react";
import { H2, H4, H5 } from "@styles/Global.styles";
import theme from "@styles/theme";

export const PlateDesignDetailWrapper = styled(({setMaxHeight,...others})=><div {...others} />)`
    overflow:hidden;
`
export const ViewMoreWrapper = styled.div`
    position: relative;
    top: -100px;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0.5), rgba(255,255,255,1));
    height: 100px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding-top: 60px;
    margin-bottom: -100px;
    cursor: pointer;
`

export const StaticDropDown = styled.div`
    background-color: #fff;
    padding: 14px 14px 10px 14px;
    border-radius: 8px;
    box-shadow: 2px 2px 12px #00000036;
    margin-bottom: 30px;
    font-weight: normal;
    font-size: 16px;
    i {
        float: right;
    };
    span{
        font-weight: 100;
    };
`

export const DesignCount = styled.div`
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
`

export const StickyTopMenu = styled(({visible, ...others})=><Menu {...others} />)`
    display: ${props=>props.visible  === true ? "block !important" : "none !important"};
    padding: 10px 0 10px 0;
`
export const MobileHeader = styled(H5)`
    display: inline-block;
    padding-right: 20px;
    font-size: 16px;
    color:white;
`

export const MobileHeaderTitle = styled(H2)`
    padding-top: 40px;
    font-size: 26px;
    margin-bottom: 0;
    color:white;
`

export const MobileHeaderParagraph = styled.div`
    font-size: 14px;
    margin-top: 8px;
    color:white;
    margin-bottom:24px;
`
export const DesktopHeaderTitle = styled(H2)`
    padding-top: 0px;
    font-size: 36px;
    margin-bottom: 0;
    color:white;
`

export const DesktopHeaderParagraph = styled.div`
    font-size: 16px;
    margin-top: 8px;
    color:white;
    margin-bottom:16px;
`

export const MobileBannerPricingAmount = styled.span`
    vertical-align: top;
    text-align: left;
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
    color: #3DAE2B;
`
export const MobilePriceLoadingMessage = styled.span`
    height: 28px;
    font-size: 16px;
    line-height: 28px;
`
export const BasicSegment = styled(Segment)`
    padding: 0 !important;
`

export const MobilePlateDesignSliderContainer = styled.div`
    
`
// export const MobilePlateDesignSliderContainer = styled.div`
//     .slick-list {
//         padding: unset !important;
//         max-height: 290px;
//     }
//     .slick-center {
//         width: 330px !important;
//     }
//     width: 100%;
//     margin: auto;
// `
export const NextArrowIcon = styled.div`
    &:before{
        color: ${theme.brand.colors.green};
    }
`
export const PrevArrowIcon = styled.div`
    &:before{
        color: ${theme.brand.colors.green};
    }
`

export const ButtonWrapper = styled.div`
    margin-top: 24px;
`