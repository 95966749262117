import React, { FunctionComponent, Fragment } from 'react'

import {
    SelectedCardContainer, DefaultCardContainer,SelectedIcon
} from './CardContainer.styles'
import { Icon } from 'semantic-ui-react'

interface CardContainerProps{
    selected: boolean,
    onSelected?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    children: object | string 
}

const CardContainer: FunctionComponent<CardContainerProps> = ({selected,onSelected,children}) =>{
    return (
        <Fragment>
            {
                selected ?
                <SelectedCardContainer>
                    <SelectedIcon>
                        <Icon name="check" />
                    </SelectedIcon>
                    {children}
                </SelectedCardContainer>
                :
                <DefaultCardContainer onClick={onSelected}>
                        {children} 
                </DefaultCardContainer>
            }
        </Fragment>
    )
}
export default CardContainer;