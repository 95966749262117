import styled from '@emotion/styled'
import mq from '@styles/mq'
import React from 'react'

export const DefaultCardContainer = styled.div`
    background-color: white;
    position: relative;
    box-shadow: 0px 6px 16px #0000001F;
    border-radius: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: left;
    ${mq({
      padding:['18px','24px','24px'],
      marginLeft:['5px','10px','36px'],
      marginRight:['5px','10px','0px']
    })}
`

export const DefaultCardContainerPreview = styled(({ floatRight, ...others }) => <div {...others} />)`
    background-color: white;
    position: relative;
    box-shadow: 0px 6px 16px #0000001F;
    border-radius: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: left;
    ${mq({
      width: ['auto','250px','294.15px'],
      padding:['18px','24px','32px 24px'],
      marginLeft:['5px','10px','20px'],
      marginRight:['5px','10px','0px']
    })}
    width: 290px;
    float: ${p => p.floatRight ? 'right' : 'unset'};

    #select-style-continue{
      button {
        width: 100%;
      }
    }
`
export const SelectedCardContainer = styled(DefaultCardContainer)`  
  box-shadow: 0 0 0px 5px #3dae2b;
  margin-left: unset !important;
`
export const SelectedIcon = styled.div`
    position: absolute;
    top: -1px;
    right: -1px;
    background-color: #3DAE2B;
    width:30px;
    height:30px;
    border-top-right-radius:12px;
    border-bottom-left-radius:12px;
    color: white;
    line-height: 30px;
    text-align:center;
    padding-left:6px;
`