import React from "react"
import {
  PlateImageContainer,
  PlateImage,
  CardDivider,
  PlateDesignInfo,
  PlateDesignName,
  PlateDesignPricing,
  PrcingFrom,
  PlatePriceTag,
  CardBadge,
  BrandsText,
  MoreDetailsButton,
  ImageLinkWrapper,
  PlateColorPaletteContainer,
  PlateDesignInfoContainer,
  CardBadgeRed,
  PlateOnlyImageContainer,
  IconWrapper,
  IconWrapperWhite,
} from "./PlateDesignCard.styles"

import { PlateColorPalette } from "../PlateColorPalette"
import theme from "@styles/theme"
import {
  ProductModelCategoryList,
  PlateCardDesignModel,
  PlateCategoryMetaInfo,
  VehicleType,
} from "@models/ProductModels"
import { buildPlateImageUrl, getInternalUrl, getWidth, isDefaultCombination } from "@utils/Helpers"
import { CardContainer } from "@components/CardContainerV2"
import { Responsive, Grid, Header } from "semantic-ui-react"
import {
  ITransactionType,
  TransactionTypesSetting,
} from "@models/ProductModels/TransactionType"
import { calculateDefaultPrice } from "@components/PlateDesignsPreview/designPreviewHelper"
import { BadgeTransactionType } from "@utils/Constant"
import { getCurrentCheckout } from "@redux/localStorage/checkout"
import { FontColor, InfoBannerPlateImage, MobileDetailContainer, MobileImageContainer, Price, PriceDetails, SelectButton, SelectedButton } from "@components/PlateDesignStyleContainerV2/MobilePlateDesignStyleContainer.styles"

export type PlateDesignCardProps = {
  apiUrl: string
  productCategoryList: ProductModelCategoryList
  formattedCombination: string
  metaInfo: PlateCategoryMetaInfo
  showMoreDetailsButton?: boolean
  selected: boolean
  transactionType?: ITransactionType
  vehicleType: VehicleType
  handleSelectCard: any
  cardKey: string | number
}

type PlateDesignCardStates = {
  plateCardDesignModel: PlateCardDesignModel
}

class PlateDesignCard extends React.Component<
  PlateDesignCardProps,
  PlateDesignCardStates
  > {
  constructor(props: PlateDesignCardProps) {
    super(props)
    this.state = {
      plateCardDesignModel: props.productCategoryList.buildDefaultCardDesignModel(),
    }
  }
  handleColorChange = (colorName: string) => {
    const newModel = this.props.productCategoryList.buildPlateCardDesignModelByPlateColor(
      colorName
    )
    this.setState({ plateCardDesignModel: newModel })
  }


  selectCard = () => {
      this.props.handleSelectCard(this.props.metaInfo.name, this.props.productCategoryList.categories[0].products[0].id)
  }

  onMouseOver = (id: string | number) => {
    console.log(id)
  }

  flipCard = (id: string | number) => {
    var a = id
    if(document && window != undefined){
      var element = document.getElementById('flip_' + id);
      const firstChild = element?.children[0];
      const secondChild = element?.children[1];

      if(window.getComputedStyle(secondChild).visibility == 'visible' && firstChild && secondChild ) {
        secondChild.style.visibility = 'hidden';
        secondChild.style.display = 'none';
        firstChild.style.visibility = 'visible';
        firstChild.style.display = 'block';
      } else if (firstChild && secondChild){
        secondChild.style.visibility = 'visible';
        secondChild.style.display = 'block';
        firstChild.style.visibility = 'hidden';
        firstChild.style.display = 'none';
      }
    }
  }

  render() {
    const {
      apiUrl,
      productCategoryList,
      formattedCombination,
      vehicleType,
      metaInfo,
      selected,
      showMoreDetailsButton,
      cardKey,
    } = this.props
    const { plateCardDesignModel } = this.state
    const plateImageUrl = buildPlateImageUrl(
      apiUrl,
      plateCardDesignModel.plate,
      formattedCombination,
      275
    )
    const plateColors = productCategoryList.plateColors
    const imageWidth = "100%"
    const imageUrl =
      metaInfo.url.length !== 0
        ? getInternalUrl(
          metaInfo.url,
          `vt=${vehicleType.id}&fcomb=${formattedCombination}`
        )
        : ""

    var transactionType = !this.props.transactionType ? TransactionTypesSetting.New : this.props.transactionType

    const price = calculateDefaultPrice(this.props.metaInfo, transactionType)

    var badgeRaw = metaInfo.isNewDesign.split("|")
    var badge = "None"
    if(badgeRaw.length === 2){
      var tt = this.props.transactionType ? this.props.transactionType.code : ""

      if(badgeRaw[1] === BadgeTransactionType.New && tt === "new" ){
        badge = badgeRaw[0]
      }
      if(badgeRaw[1] === BadgeTransactionType.All){
        badge = badgeRaw[0]
      }
      if(badgeRaw[1] === BadgeTransactionType.Redesign && tt === "redesign" ){
        badge = badgeRaw[0]
      }

      if(tt === undefined || tt === ''){
        badge = badgeRaw[0]
      }
    }

    var fontColors = plateColors.length;
    var windowWidth = getWidth()

    return (
      <MobileImageContainer>
        <div id={'flip_' + productCategoryList.categories[0].products[0].id} className="show-plate" onClick={() => this.flipCard(productCategoryList.categories[0].products[0].id)}>
          <div  className="plate-image">
            <IconWrapperWhite>
              <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5771 5.47107C10.7175 5.33062 10.908 5.25171 11.1067 5.25171C11.3053 5.25171 11.4958 5.33062 11.6363 5.47107L12.4581 6.29438C12.5111 5.47486 12.3391 4.65643 11.9606 3.92764C11.5821 3.19884 11.0115 2.58743 10.3105 2.15956C9.60954 1.73169 8.80492 1.50364 7.98369 1.5001C7.16247 1.49656 6.35591 1.71765 5.65128 2.13947C5.55391 2.19783 5.45747 2.26056 5.36478 2.32672C5.20234 2.44157 5.00093 2.48718 4.80485 2.45353C4.60878 2.41987 4.43411 2.30971 4.31926 2.14727C4.20441 1.98483 4.1588 1.78342 4.19245 1.58734C4.22611 1.39127 4.33627 1.2166 4.49872 1.10175C4.62355 1.01436 4.75058 0.931036 4.87978 0.852076C5.80212 0.299968 6.85582 0.00572096 7.93076 8.25835e-05C9.00571 -0.0055558 10.0624 0.277622 10.9905 0.820023C11.9186 1.36242 12.684 2.14414 13.2066 3.08347C13.7293 4.02281 13.9901 5.08528 13.9617 6.15987L14.6518 5.47107C14.7214 5.40158 14.8041 5.3465 14.8951 5.30896C14.9861 5.27142 15.0835 5.25217 15.1819 5.2523C15.2803 5.25243 15.3777 5.27194 15.4686 5.30972C15.5595 5.3475 15.642 5.4028 15.7115 5.47248C15.781 5.54215 15.8361 5.62483 15.8736 5.7158C15.9111 5.80676 15.9304 5.90422 15.9303 6.00263C15.9301 6.10103 15.9106 6.19845 15.8728 6.28931C15.8351 6.38017 15.7798 6.46271 15.7101 6.5322L13.6705 8.57017C13.5301 8.71063 13.3396 8.78954 13.1409 8.78954C12.9423 8.78954 12.7518 8.71063 12.6113 8.57017L10.5749 6.52907C10.4351 6.38837 10.3568 6.19796 10.3572 5.99962C10.3576 5.80128 10.4367 5.6112 10.5771 5.47107Z" fill="#f8f8f8"/>
                <path d="M2.24103 3.43241C2.31059 3.36282 2.39318 3.30761 2.48408 3.26994C2.57498 3.23228 2.67242 3.21289 2.77081 3.21289C2.86921 3.21289 2.96664 3.23228 3.05754 3.26994C3.14844 3.30761 3.23103 3.36282 3.30059 3.43241L5.33701 5.47258C5.4065 5.54225 5.46158 5.62493 5.49912 5.71589C5.53666 5.80686 5.55591 5.90432 5.55578 6.00273C5.55565 6.10113 5.53614 6.19854 5.49836 6.28941C5.46058 6.38027 5.40528 6.4628 5.3356 6.53229C5.26593 6.60178 5.18325 6.65687 5.09229 6.69441C5.00132 6.73194 4.90386 6.7512 4.80546 6.75107C4.70705 6.75093 4.60964 6.73142 4.51877 6.69365C4.42791 6.65587 4.34538 6.60056 4.27589 6.53089L3.45445 5.7079C3.40143 6.52738 3.5735 7.34577 3.95205 8.07452C4.3306 8.80327 4.90121 9.41463 5.60216 9.84247C6.30312 10.2703 7.10771 10.4983 7.92891 10.5018C8.7501 10.5054 9.55663 10.2843 10.2612 9.8625C10.3595 9.80351 10.4572 9.73984 10.5509 9.67337C10.6311 9.61542 10.722 9.57394 10.8183 9.55133C10.9147 9.52872 11.0145 9.52542 11.1121 9.54162C11.2098 9.55783 11.3032 9.59321 11.3871 9.64574C11.4709 9.69826 11.5436 9.76689 11.6007 9.84764C11.6579 9.9284 11.6986 10.0197 11.7203 10.1162C11.742 10.2128 11.7443 10.3127 11.7272 10.4101C11.7101 10.5076 11.6738 10.6007 11.6205 10.6841C11.5672 10.7674 11.4979 10.8394 11.4166 10.8958C11.2918 10.9842 11.1623 11.0687 11.0315 11.1474C10.1091 11.6995 9.05539 11.9938 7.98042 11.9994C6.90545 12.005 5.8487 11.7218 4.92061 11.1794C3.99252 10.6369 3.22716 9.85517 2.70451 8.91579C2.18187 7.97641 1.92113 6.9139 1.94954 5.83929L1.25949 6.52933C1.11746 6.66159 0.929641 6.73357 0.735593 6.73009C0.541545 6.72661 0.356423 6.64794 0.219229 6.51067C0.0820345 6.37339 0.00347926 6.18822 0.00011282 5.99417C-0.00325362 5.80012 0.0688316 5.61234 0.201182 5.47039L2.24103 3.43241Z" fill="#f8f8f8"/>
              </svg>
            </IconWrapperWhite> 
            <InfoBannerPlateImage src={windowWidth <= Number(Responsive.onlyTablet.maxWidth) ? metaInfo.featureImageMobile : metaInfo.featureImageMobile} width={320}></InfoBannerPlateImage>
          </div>
          
          <PlateOnlyImageContainer className="plate-plate">
            <IconWrapper>
              <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5771 5.47107C10.7175 5.33062 10.908 5.25171 11.1067 5.25171C11.3053 5.25171 11.4958 5.33062 11.6363 5.47107L12.4581 6.29438C12.5111 5.47486 12.3391 4.65643 11.9606 3.92764C11.5821 3.19884 11.0115 2.58743 10.3105 2.15956C9.60954 1.73169 8.80492 1.50364 7.98369 1.5001C7.16247 1.49656 6.35591 1.71765 5.65128 2.13947C5.55391 2.19783 5.45747 2.26056 5.36478 2.32672C5.20234 2.44157 5.00093 2.48718 4.80485 2.45353C4.60878 2.41987 4.43411 2.30971 4.31926 2.14727C4.20441 1.98483 4.1588 1.78342 4.19245 1.58734C4.22611 1.39127 4.33627 1.2166 4.49872 1.10175C4.62355 1.01436 4.75058 0.931036 4.87978 0.852076C5.80212 0.299968 6.85582 0.00572096 7.93076 8.25835e-05C9.00571 -0.0055558 10.0624 0.277622 10.9905 0.820023C11.9186 1.36242 12.684 2.14414 13.2066 3.08347C13.7293 4.02281 13.9901 5.08528 13.9617 6.15987L14.6518 5.47107C14.7214 5.40158 14.8041 5.3465 14.8951 5.30896C14.9861 5.27142 15.0835 5.25217 15.1819 5.2523C15.2803 5.25243 15.3777 5.27194 15.4686 5.30972C15.5595 5.3475 15.642 5.4028 15.7115 5.47248C15.781 5.54215 15.8361 5.62483 15.8736 5.7158C15.9111 5.80676 15.9304 5.90422 15.9303 6.00263C15.9301 6.10103 15.9106 6.19845 15.8728 6.28931C15.8351 6.38017 15.7798 6.46271 15.7101 6.5322L13.6705 8.57017C13.5301 8.71063 13.3396 8.78954 13.1409 8.78954C12.9423 8.78954 12.7518 8.71063 12.6113 8.57017L10.5749 6.52907C10.4351 6.38837 10.3568 6.19796 10.3572 5.99962C10.3576 5.80128 10.4367 5.6112 10.5771 5.47107Z" fill="#707070"/>
                <path d="M2.24103 3.43241C2.31059 3.36282 2.39318 3.30761 2.48408 3.26994C2.57498 3.23228 2.67242 3.21289 2.77081 3.21289C2.86921 3.21289 2.96664 3.23228 3.05754 3.26994C3.14844 3.30761 3.23103 3.36282 3.30059 3.43241L5.33701 5.47258C5.4065 5.54225 5.46158 5.62493 5.49912 5.71589C5.53666 5.80686 5.55591 5.90432 5.55578 6.00273C5.55565 6.10113 5.53614 6.19854 5.49836 6.28941C5.46058 6.38027 5.40528 6.4628 5.3356 6.53229C5.26593 6.60178 5.18325 6.65687 5.09229 6.69441C5.00132 6.73194 4.90386 6.7512 4.80546 6.75107C4.70705 6.75093 4.60964 6.73142 4.51877 6.69365C4.42791 6.65587 4.34538 6.60056 4.27589 6.53089L3.45445 5.7079C3.40143 6.52738 3.5735 7.34577 3.95205 8.07452C4.3306 8.80327 4.90121 9.41463 5.60216 9.84247C6.30312 10.2703 7.10771 10.4983 7.92891 10.5018C8.7501 10.5054 9.55663 10.2843 10.2612 9.8625C10.3595 9.80351 10.4572 9.73984 10.5509 9.67337C10.6311 9.61542 10.722 9.57394 10.8183 9.55133C10.9147 9.52872 11.0145 9.52542 11.1121 9.54162C11.2098 9.55783 11.3032 9.59321 11.3871 9.64574C11.4709 9.69826 11.5436 9.76689 11.6007 9.84764C11.6579 9.9284 11.6986 10.0197 11.7203 10.1162C11.742 10.2128 11.7443 10.3127 11.7272 10.4101C11.7101 10.5076 11.6738 10.6007 11.6205 10.6841C11.5672 10.7674 11.4979 10.8394 11.4166 10.8958C11.2918 10.9842 11.1623 11.0687 11.0315 11.1474C10.1091 11.6995 9.05539 11.9938 7.98042 11.9994C6.90545 12.005 5.8487 11.7218 4.92061 11.1794C3.99252 10.6369 3.22716 9.85517 2.70451 8.91579C2.18187 7.97641 1.92113 6.9139 1.94954 5.83929L1.25949 6.52933C1.11746 6.66159 0.929641 6.73357 0.735593 6.73009C0.541545 6.72661 0.356423 6.64794 0.219229 6.51067C0.0820345 6.37339 0.00347926 6.18822 0.00011282 5.99417C-0.00325362 5.80012 0.0688316 5.61234 0.201182 5.47039L2.24103 3.43241Z" fill="#707070"/>
              </svg>
            </IconWrapper>          

            {this.props.handleSelectCard === undefined ? (
              imageUrl.length !== 0 ? (
                <ImageLinkWrapper to={imageUrl} state={{selectedPlateDesign: this.state.plateCardDesignModel}}>
                  <PlateImage
                    theme={theme}
                    src={plateImageUrl}
                    width={imageWidth}
                    alt={`${formattedCombination} plate image`}
                  />
                </ImageLinkWrapper>
              ) : (
                  <PlateImage
                    src={plateImageUrl}
                    width={imageWidth}
                    alt={`${formattedCombination} plate image`}
                  />
                )
            ) : (
                <PlateImage
                  src={plateImageUrl}
                  width={imageWidth}
                  alt={`${formattedCombination} plate image`}
                />
              )}
          </PlateOnlyImageContainer>
        </div>

        <MobileDetailContainer>
            <Header>{metaInfo.name}</Header>
            <Price>                
                <FontColor>+{fontColors || 0} font colours</FontColor>                
                <PriceDetails><span>${price}</span></PriceDetails>
            </Price>                
            {!this.props.selected && <span id={"csc-select-"}><SelectButton onClick={() => {this.selectCard()}} id="csc-select" buttonType='secondary' color={theme.brand.colors.black} textColor={theme.brand.colors.white}>SELECT</SelectButton></span>}
            {this.props.selected && <span id={"csc-select-"}><SelectedButton onClick={() => {this.selectCard()}} id="csc-select" buttonType='secondary' color={theme.brand.colors.black} textColor={theme.brand.colors.white}>SELECTED</SelectedButton></span>}
        </MobileDetailContainer>
      </MobileImageContainer>
    )
  }
}

export default PlateDesignCard